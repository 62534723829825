/**
 * @param {string|number|Date} date something that represents a date
 * @returns {Date}
 */
export const asDate = date => {
  let dateObj;
  if (typeof date === 'string') dateObj = new Date(Date.parse(date));
  else if (typeof date === 'number') dateObj = new Date(date);

  if (date instanceof Date) dateObj = date;
  if (!(dateObj instanceof Date)) throw new Error(`date with the value '${ date }' is not valid`);
  return dateObj;
}

const padDate = name => (`${ name }`).padStart(2, '0');

export const parseDate = date => {
  const dateObj = asDate(date);

  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const seconds = dateObj.getSeconds();
  const milliseconds = dateObj.getMilliseconds();
  const offset = dateObj.getTimezoneOffset();

  const isoDate = `${year}-${padDate(month)}-${padDate(day)}`;

  return {
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
    milliseconds,
    offset,
    isoDate,
  }
}

export const dateAsIso = date => {
  //  convert to date obj
  const dateObj = asDate(date);
  const newDate = new Date(dateObj);

  //  adjust for offset
  const offset = newDate.getTimezoneOffset();
  newDate.setMinutes(newDate.getMinutes() - offset);

  //  calculate the diff from prev to now
  //  final value in 0-hundred hours
  const diffInMs = newDate.getTime() - dateObj.getTime();
  const diffInSecs = diffInMs / 1000;
  const diffInMins = diffInSecs / 60;
  const diffInHrs = diffInMins / 60;

  const diffWholeHrs = Math.floor(diffInHrs);
  const remainingMins = 60 * (diffInHrs - diffWholeHrs);
  const oHundredHrs = (`${ diffWholeHrs * 100 + remainingMins }`).padStart(4, '0');

  //  calculate different from original date to new date;
  const sign = oHundredHrs < 0 ? '-' : '+';
  const locale = `${ sign }${ Math.abs(oHundredHrs) }`;

  const isoBase = newDate.toISOString();
  const datetime = isoBase.slice(0, isoBase.indexOf('Z'));

  const iso = `${ datetime }${ locale }`;
  return iso;
}


const now = () => parseDate(Date.now());

export const constructDate = (year = now().year, month = now().month, day = now().day, hour = 0, minute = 0, second = 0, milliseconds = 0) => {
  return new Date(year, month - 1, day, hour, minute, second, milliseconds);
}

export const getMonthRange = (year, month) => {
  const start = constructDate(year, month, 1);
  const end = constructDate(year, month + 1, 0, 23, 59, 59, 999);

  return {
    start: dateAsIso(start),
    end: dateAsIso(end)
  }
}

export const isSameDay = (date1, date2) => {
  const obj1 = parseDate(date1);
  const obj2 = parseDate(date2);

  return (
    obj1.year === obj2.year &&
    obj1.month === obj2.month &&
    obj1.day === obj2.day
  )
}

export const isSameMonth = (date1, date2) => {
  const obj1 = parseDate(date1);
  const obj2 = parseDate(date2);

  return (
    obj1.year === obj2.year &&
    obj1.month === obj2.month
  )
}


export const compareDate = (date1, date2) => {
  const obj1 = parseDate(date1);
  const obj2 = parseDate(date2);

  const fields = [
    'year',
    'month',
    'day',
    'hours',
    'minutes',
    'seconds',
    'milliseconds'
  ];

  const compared = fields.map(prop => {
    if (obj1[prop] > obj2[prop]) return 1;
    if (obj1[prop] < obj2[prop]) return -1;
    return 0;
  });

  return compared.reduce((size, compare) => {
    if (size !== 0) return size;
    return compare;
  }, 0);
}

export const isInRange = (range, date) => {
  const isHigherThanStart = compareDate(date, range.start) >= 0;
  const isLowerThanEnd = compareDate(date, range.end) <= 0;

  return isHigherThanStart && isLowerThanEnd;
}


export const sortByRecent = (dateA, dateB) => {
  const stampA = asDate(dateA).getTime();
  const stampB = asDate(dateB).getTime();

  if (stampA < stampB) return 1;
  if (stampA > stampB) return -1;
  return 0;
}


export const dateFormats = {
  TIME_12_HOURS: { hour: 'numeric', minute: 'numeric', hour12: true },
  DATE_LONG: { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' },
  DATE_SHORT: { month: 'short', year: 'numeric' },
  DATE_DMY: { day:'numeric', month: 'short', year: 'numeric' },
  DATE_NUMERIC:{ day: '2-digit', month: '2-digit', year: 'numeric' }
}

export const toDateString = (date, options) => (
  asDate(date).toLocaleString('en-Au', options)
)