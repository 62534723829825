import React from 'react'
import { AzureAD, AuthenticationState } from 'react-aad-msal';

import authContext from './authContext'
import useRequest from '../../hooks/useRequest';
import { dateAsIso } from '../../utils/date-time';

import aadConfig from './aadConfig';

// let activeInterceptor;
let prevAuthContext = {};
const matchAPIRequest = (config) => (
  config.url.indexOf(process.env.REACT_APP_API_URL) === 0 ||
  config.url.indexOf(process.env.REACT_APP_STATSAPI_URL) === 0 
)

const AuthProvider = (props) => {
  const { children } = props;
  const { setInterceptor } = useRequest();
  
  return (
    <AzureAD provider={aadConfig}>
      {
        (authInformation) => {
          const { jwtIdToken: newJWT } = authInformation?.accountInfo ?? {};
          const { jwtIdToken: oldJWT } = prevAuthContext?.accountInfo ?? {};

          if (newJWT !== oldJWT) {
            setInterceptor(matchAPIRequest, () => ({
              headers: {
                'Authorization': `Bearer ${ newJWT }`,
                'Organisation': 'InnoWell Dev',
                'X-Local-Time': dateAsIso(Date.now()),
              }
            }));
          }

          const {
            given_name: givenName,
            family_name: familyName,
            email,
            Organisation: organisation
          } = authInformation.accountInfo?.account?.idTokenClaims ?? {};
          const name = `${ givenName } ${ familyName }`;

          prevAuthContext = {
            isInProgress: authInformation.authenticationState === AuthenticationState.InProgress,
            isAuthenticated: authInformation.authenticationState === AuthenticationState.Authenticated,
            isUnauthenticated: authInformation.authenticationState === AuthenticationState.Unauthenticated,       
            user: {
              name,
              email,
              firstName: givenName,
              lastName: familyName,
              organisation
            },
            ...authInformation
          }

          return (
            <authContext.Provider value={ prevAuthContext }>
              { children }
            </authContext.Provider>
          )
        }
      }
    </AzureAD>
  )
}

export default AuthProvider
