import React from 'react';
import { createTheme, useTheme, ThemeProvider } from '@material-ui/core/styles';

import color from './tokens/Color';
import { fontFamily } from './tokens/Typography';
import { CssBaseline } from '@material-ui/core';


const elevation = {
  1: '0px 1px 3px rgba(0, 0, 0, 0.08), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.12)',
  2: '0px 3px 3px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08)',
  8: '0px 5px 5px rgba(0, 0, 0, 0.2), 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12)',
  dunno: '0px 2px 4px rgba(0, 0, 0, 0.07), 0px 0px 2px rgba(0, 0, 0, 0.2)', // TODO: please name me or update me.
}

const Theme = (props) => {
  const { children } = props;

  const muiTheme = useTheme();
  const thriveTheme = createTheme({
    typography: {
      manrope: fontFamily.manrope,
      poppins: fontFamily.poppins,
    },
    palette: {
      primary: {
        ...color.purple,
        lightest: color.purple[50],
        light: color.purple[300],
        main: color.purple[500],
        dark: color.purple[700],
        contrastText: '#FFFFFF',
      },
      secondary: {
        ...color.green,
        light: color.green[200],
        main: color.green[800],
        dark: color.green[900],
        contrastText: color.white,
      },
      grey: {
        ...color.neutral,
        lightest: color.neutral[50],
        light: color.neutral[100],
        main: color.neutral[700],
        dark: color.neutral[900],
      },
      background: {
        default: color.white,
      },
      transparent: {
        ...color.transparent
      },
      error: {
        ...color.red,
        light: color.red[200],
        main: color.red[800],
        dark: color.red[900],
        contrastText: color.white,
      },
      info: {
        ...color.blue,
        light: color.blue[200],
        main: color.blue[800],
        dark: color.blue[900],
        contrastText: color.white,
      },
      warning: {
        ...color.orange,
        light: color.orange[200],
        main: color.orange[800],
        dark: color.orange[900],
        contrastText: color.white,
      },
      success: {
        ...color.green,
        light: color.green[200],
        main: color.green[800],
        dark: color.green[900],
        contrastText: color.black,
      },
      white: color.white,
      black: color.black,
      hr: {
        onGrey: color.transparent[800],
        onWhite: color.transparent[900],
      },
      text: {
        link: color.purple[600],
        disabled: color.neutral[500],
        lowEmphasis: color.neutral[600],
        mediumEmphasis: color.neutral[700],
        highEmphasis: color.neutral[900],
      },
      neutral: {
        ...color.neutral
      }
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            WebkitFontSmoothing: 'antialiased',
          },
          body: {
            color: color.neutral[700],
            backgroundColor: color.white,
            fontFamily: fontFamily.manrope,
          },
          a: {
            color: color.purple[600],
          },
          button: {
            '&[type=button].link': {
              color: color.purple[600],
              userSelect: 'none',
              backgroundColor: 'transparent',
              padding: 0,
              border: 0,
              textAlign: 'left',
            }
          }
        },
      },
      // need to figure out how to store these overrides in context of component
      MuiContainer: {
        root: {
          paddingLeft: muiTheme.spacing(2.5),
          paddingRight: muiTheme.spacing(2.5),
          maxWidth: muiTheme.spacing(110), // TODO: This needs to be centralised into a reusable value

          [muiTheme.breakpoints.up('sm')]: {
            paddingLeft: muiTheme.spacing(5),
            paddingRight: muiTheme.spacing(5),
          },
        },
        fixed: {
          maxWidth: `unset !important`,
        },
        maxWidthXl: {
          width: '100%',
          maxWidth: '100%',

          [muiTheme.breakpoints.up('md')]: {
            paddingLeft: muiTheme.spacing(5),
            paddingRight: muiTheme.spacing(5),
          },
          [muiTheme.breakpoints.up('lg')]: {
            maxWidth: 1200,
            paddingLeft: muiTheme.spacing(10),
            paddingRight: muiTheme.spacing(10),
          }
        }
      },
      MuiInputBase: {
        root: {
          fontFamily: fontFamily.manrope,
          /**
					 * TODO: Handle disabled inputs consistently both in designs and code.
					 * this fix hardcodes the colour from MUI's disabled input, which does not
					 * include a fix for safari.
					 */
					'&.Mui-disabled': {
						'-webkit-text-fill-color': 'rgb(127, 124, 138)'
					},
        },
      },
      MuiInputLabel: {
        outlined: {
          transform: 'translate(14px, 12px) scale(1)'
        }
      },
			MuiSelect: {
				selectMenu: {
					whiteSpace: 'unset'
				},
			},
      MuiOutlinedInput: {
        root: {
          background: 'white',
          borderRadius: muiTheme.spacing(1.25),
          overflow: 'hidden',
        },
        input: {
          padding: muiTheme.spacing(1.25, 2),
        }
      },
      MuiButton: {
        root: {
          borderRadius: muiTheme.spacing(4),
          textTransform: 'none',
          fontFamily: fontFamily.poppins,
          fontWeight: '500',
          lineHeight: '24px',
          fontSize: '14px',
        },
        sizeSmall: {
          padding: muiTheme.spacing(1.5, 3),
        },
        sizeLarge: {
          padding: muiTheme.spacing(1.75, 3),
          fontSize: '15px',
          fontWeight: '500',
        },
        contained: {
          boxShadow: elevation[2],
        },
        outlined: {
          color: color.purple[600],
        },
      },
      MuiTableRow:{
        root: {
          '&.MuiTableRow-hover:hover > td:last-child' :{
            paddingRight: muiTheme.spacing(0),
            '& .absoluteDiv':{
              backgroundColor:'rgba(0, 0, 0, 0.04)',
            }
          },
          '& td:nth-last-child(2)' : {
            paddingRight: muiTheme.spacing(5.5)
          },
          // Absolute positioning last item of the table header to match the row styling
          '& th:last-child' : {
            position: 'absolute',
            right: muiTheme.spacing(5),
            borderBottom: 'unset',
            top: 'auto',
            left: 'auto',
            height:  muiTheme.spacing(6.25),
          },
        },
      },
      MuiTableCell: {
        body:{
          whiteSpace: 'nowrap',
        }
      },
      MuiFab: {
        root: {
          textTransform: 'none',
          fontFamily: fontFamily.poppins,
          fontWeight: '500',
          fontSize: '15px',
          lineHeight: 1.333,
          letterSpacing: '0.1px',
          zIndex: muiTheme.zIndex.speedDial,
        },
      },
      MuiToolbar: {
        gutters: {
          paddingLeft: muiTheme.spacing(2.5),
          paddingRight: muiTheme.spacing(2.5),
        }
      },
      MuiPaper: {
        rounded: {
          borderRadius: muiTheme.spacing(1.25),
        },
        elevation1: {
          boxShadow: elevation[1],
        },
        elevation8: {
          boxShadow: elevation[8]
        }
      },
      MuiChip: {
        root: {
          borderRadius: muiTheme.spacing(1),
          fontFamily: fontFamily.poppins,
          fontSize: '12px',
          backgroundColor: color.grey[100],
        },
        label: {
          paddingLeft: muiTheme.spacing(1),
          paddingRight: muiTheme.spacing(1),
        },
        clickable: {
          '&:active': {
            boxShadow: 'none',
          },
          '&.MuiChip-outlinedPrimary:hover, &.MuiChip-outlinedPrimary:focus': {
            backgroundColor: `${color.purple[50]} !important`,  // battling a cascade misorganisation with mui
          }
        },
        outlined: {
          border: `1px solid ${color.neutral[500]}`,
          backgroundColor: color.white,
          color: color.neutral[900],
        },
        outlinedPrimary: {
          color: color.purple[600],
          borderColor: color.purple[300],
          backgroundColor: color.purple[50],
        }
      },
      MuiAppBar: {
        root: {
          color: color.neutral[900],
          backgroundColor: color.white,
          boxShadow: elevation.dunno,
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: '#040068',
          fontWeight: 400,
          fontSize: 14,
          textAlign: 'center',
          borderRadius: muiTheme.spacing(1.25),
          width: muiTheme.spacing(50),
          padding: muiTheme.spacing(1, 2),
        }
      },
    },
    props: {
      MuiContainer: {
        maxWidth: false,
      },
      MuiTooltip: {
        position: 'bottom',
      }
    }
  });

  return (
    <ThemeProvider theme={ thriveTheme }>
      <CssBaseline />
      { children }
    </ThemeProvider>
  );
}

export default Theme;