import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton';
import { MoreVert } from '@material-ui/icons';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Typography from '../../components/Typography';

const invokeIfFunction = (value, arg) => typeof value === 'function' ? value(arg) : value;

const TableContextMenuButton = props => {
	const {
		className,
		disabled,
		active,
		onActive,
	} = props;

	return (
		<IconButton
			className={className}
			disabled={disabled}
			onClick={onActive}
			active={active}
			name='ContextMenuButton'
		>
			<MoreVert />
		</IconButton>
	)
}

const TableContextMenu = props => {
	const {
		row,
		items = [],
		className,
		confirmCtx,
	} = props;
	
	const [anchor, setAnchor] = useState(null);

	const openContext = e => setAnchor(e.currentTarget);
	const closeContext = () => setAnchor(null);

	const onMenuItemClick = item => () => {
		closeContext();

		const {
			disableConfirmation,
			confirmIsBad,
			body,

			acceptLabel,
			onAccept,
			declineLabel,
			onDecline,
		} = item;
		
		if (disableConfirmation === true) onAccept(row)();
		else {
			confirmCtx.open({
				body: invokeIfFunction(body, row),
				confirmIsBad: invokeIfFunction(confirmIsBad, row),

				acceptLabel: invokeIfFunction(acceptLabel, row),
				onAccept: invokeIfFunction(onAccept, row),
				declineLabel: invokeIfFunction(declineLabel, row),
				onDecline: invokeIfFunction(onDecline, row),
			});
		}
	}
	
	return (
		<>
			<TableContextMenuButton
				className={className}
				active={anchor}
				onActive={openContext}
			/>

			<Menu
				id='table-row-context-menu'
				anchorEl={anchor}
				keepMounted
				open={Boolean(anchor)}
				onClose={closeContext}
			>
				{
					items.map(item => {
						const { menuLabel: _menuLabel } = item;
						const menuLabel = invokeIfFunction(_menuLabel, row);

						return (
							<MenuItem key={menuLabel} onClick={onMenuItemClick(item)}>
								<Typography>
									{ menuLabel }
								</Typography>
							</MenuItem>
						)
					})
				}
			</Menu>
		</>
	)
}

export default TableContextMenu
