import React from 'react';
import clsx from 'clsx';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core';

import Typography from '../../Typography';

const useStyles = makeStyles(theme => ({
  root: ({ bleeder }) => {
    if (bleeder) return { }
    
    return {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    }
  },
  container: {
    position: 'relative',
    zIndex: 0,
    width: '100%',

    '&:after': {
      content: '""',
      position: 'absolute',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      zIndex: -1,
    }
  },
  title: { 
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    color: theme.palette.text.lowEmphasis,
    marginLeft: theme.spacing(1),
  },
  inlineTitle: {
    display: 'inline-flex',
  },
  greyBackground: {
    '&:after': {
      backgroundColor: theme.palette.grey.lightest,
    }
  },
  bleeder: {
    '&:after': {
      height: '50%',
    }
  },
  centered: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

export default function DialogSection(props) {
  const {
    hero,
    bleeder,
    centered,
    
    title,
    subtitle,
    children,
  } = props;

  const classes = useStyles();

  const classList = [classes.container];
  if (hero || bleeder) classList.push(classes.greyBackground);
  if (bleeder) classList.push(classes.bleeder);
  if (centered) classList.push(classes.centered);

  const hasSubtitle = (subtitle && subtitle.length > 0)

  return (
    <div className={ clsx(...classList) }>
      <Container maxWidth='xs'>
        <div className={ classes.root }>
          {
            title && (
              <Typography type='heading' className={ clsx(classes.title, (hasSubtitle) && classes.inlineTitle) }>
                { title }
              </Typography>
            )
          }
          {
            subtitle && (
              <Typography type='paragraph' preset={4} color='lowEmphasis' className={ clsx(classes.subtitle, (hasSubtitle) && classes.inlineTitle) }>
                { subtitle }
              </Typography>
            )
          }
          { children }
        </div>
      </Container>
    </div>
  )
}
