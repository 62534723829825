import  { useState, useEffect } from 'react'
import useRequest from '../../hooks/useRequest';
import organisationContext from './organisationContext'

import useAuth from '../../hooks/useAuth';


export const OrganisationProvider = (props) => {

  const { isAuthenticated } = useAuth();

    const request = useRequest();
    const { children } = props;
  
    const [hasLoaded, setHasLoaded] = useState(false)
    const [userData, setUserData] = useState({})

  const getOrganisationData = async () => {
    if (!isAuthenticated) return;
    const { data } = await request.get(`${process.env.REACT_APP_API_URL}/OrganisationProfile`);
    
    setUserData(data);
    setHasLoaded(true);
  }
  
  useEffect(getOrganisationData, [isAuthenticated]);

  const organisationProfile = {
    ...userData,
    hasLoaded
  }


  return (
    <organisationContext.Provider value={organisationProfile}>
      { children }
    </organisationContext.Provider>
  )
}