import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    height: ({ height }) => height ?? '100%',
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

const PageLoad = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  )
}

export default PageLoad;