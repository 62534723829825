import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

import useAuth from '../../hooks/useAuth';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import InnoWellLogo from '../InnoWellLogo';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1, // TODO: the header needs its own z-index value, not this magic number crap
    height: theme.spacing(7),
    display: 'flex',
    justifyContent: 'space-around',
  },
  toolBar: {
    alignSelf: 'flex-start',
  }
}));

const AppHeader = (props) => {
  const {
    context,
  } = props;

  const { isAuthenticated } = useAuth();

  const openMenu = (event) => {    
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    
    if (context?.open) context.open();
  }

  const classes = useStyles();
  
  return (
    <AppBar position='sticky' color='inherit' className={ classes.root }>
      <Toolbar variant='dense' className={ classes.toolBar }>
        {
          isAuthenticated && (
            <Hidden mdUp>
              <IconButton edge='start' className={ classes.menuButton } color='inherit' aria-label='menu' onClick={ openMenu }>
                <MenuIcon />
              </IconButton>
            </Hidden>
          )
        }
        <InnoWellLogo />
      </Toolbar>
    </AppBar>
  );
}

export default AppHeader;
