import React, { createContext, useContext, useMemo, useState } from 'react';
import { validate as isUUID } from 'uuid'

import teamContext, { LookupFn } from '../../context/TeamsProvider'
import useRequest from '../../hooks/useRequest';
import { AsyncState, useDeferredAsyncState } from '../../hooks/useAsyncState';

type TeamOption = {
	value: 'All Teams' | 'Unspecified' | string
	text: string
}

type TeamFilter = {
	loading: boolean
	value: string
	questionOptions: TeamOption[]
	onChange: (team: string) => void
	lookup?: LookupFn
}

const teamFilterContext = createContext<TeamFilter>({
	loading: true,
	value: 'All Teams',
	questionOptions: [],
	onChange: () => {}
});
export default teamFilterContext;

export const TeamFilterProvider: React.FC = ({ children }) => {
	const teamStore = useContext(teamContext);

	const questionOptions = useMemo(() => {
		const opts = (teamStore.data?.hierarchy ?? []).map(
			({ item, children }) => ({ value: item.id, text: item.teamName, item, children })
		).sort((a, b) => (
			a.text.toLowerCase() > b.text.toLowerCase() ? 1 : (
				a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 0
			)
		));
		return ([
			// TODO: This is dodgy.
			// The Team Filter Provider should be returning the root item,
			// and the array of children in the same format, but requires some
			// TS rework to better translate the new API model into recognised types. 
			{value: 'All Teams', text: 'All Teams', item: {id: 'All Teams', teamName: 'All Teams'}},
			{value: 'Unspecified', text: 'Unspecified', item: {id: 'Unspecified', teamName: 'Unspecified'}},
			...opts
		])
	}, [teamStore.data, teamStore.state]);

	const [value, onChange] = useState<string>('All Teams');

	const ctx: TeamFilter = {
		onChange,
		value,
		questionOptions,
		loading: teamStore.state !== AsyncState.FULFILLED,
		lookup: teamStore.data?.lookup,
	}

	return (
		<teamFilterContext.Provider
			value={ctx}
			children={children}
		/>
	);
};

export enum IReports {
	UsageReport = '/UsageReport',
	ActivityReport = '/ActivityReport',
	ContentViewedReport = '/ContentViewedReport',
	ResultReport = '/ResultReport',
	QuestionnaireReport = '/QuestionnaireReport',
}

type Args = {
	team: 'All Teams' | 'Unspecified' | string | null
	[param: string]: any
}

const base = process.env.REACT_APP_STATSAPI_URL;
export const useReportAPIStore = (report: IReports, args: Args) => {
	const { get } = useRequest();

	const store = useDeferredAsyncState(async () => {
		const params: { [params: string]: any } = { ...(args as any) };

		//	only request with ?team if team is a uuid.
		if (!isUUID(params.team)) delete params.team;

		const path = args.team === 'All Teams' ? '' : '/Team';
		const query = new URLSearchParams(params);

		const { data } = await get(
			`${base + '/v2' + report + path}?${query.toString()}`
		)

		return data;
	})

	return store;
}