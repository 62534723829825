import React, { useEffect, useState, useRef } from 'react'
import useToggle from '../../hooks/useToggle';
import modalContext from './modalContext'


export const useModalCloseCallback = (ctx, callback) => {
  useEffect(() => {
    ctx.addCloseCallback(callback);
    return () => ctx.removeCloseCallback(callback);
  }, [ctx, callback])
}

const ModalProvider = (props) => {
  const { children } = props;

  const [ modalProps, setModalProps ] = useState({});

  const onCloseCallbacks = useRef(new Set());

  const modalState = useToggle();

  const open = (props) => {
    Promise.resolve().then(() => { 
			setModalProps(props ?? {});
			modalState.open();
		});
  }

  const close = () => {
    Promise.resolve().then(() => {      
      setModalProps({})
      modalState.close();

      Array.from(onCloseCallbacks.current)
        .filter(fn => typeof fn === 'function')
        .forEach(fn => fn());
    });
  }

  const updateModalProps = (updatedProps) => {
    Promise.resolve().then(() => 
      setModalProps({
        ...modalProps,
        ...updatedProps
      })
    )
  }

  const addCloseCallback = fn => {
    onCloseCallbacks.current.add(fn);
    return () => removeCloseCallback(fn);
  };
  const removeCloseCallback = fn => onCloseCallbacks.current.delete(fn);

  const ctx = {
    isOpen: modalState.isShowing,
    open, close,
    
    modalState,
    modalProps,

    updateModalProps,
    addCloseCallback,
    removeCloseCallback,
  }

  return (
    <modalContext.Provider value={ ctx }>
      { children }
    </modalContext.Provider>
  )
}

export default ModalProvider
