import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useAuth from '../../hooks/useAuth';
import Typography from '../Typography';

const OrgName = () => {

  const useStyles = makeStyles((theme) => ({
    label: {
      color: theme.palette.neutral[700],
      fontSize: theme.typography.pxToRem(16),
      margin: theme.spacing(5,2.5,0,2.75),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  }));

  const { user: {organisation} } = useAuth();
  const classes = useStyles();
  return (
    <Typography type='label' preset={1} component='span' className={ classes.label }>
      { organisation }
    </Typography>
  )
}

export default OrgName;


