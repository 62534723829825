import React, { Suspense } from 'react'
import { Container, makeStyles } from '@material-ui/core';

import CoreProvider from '../../context/CoreProvider';
import useToggle from '../../hooks/useToggle';

import AppHeader from '../AppHeader';
import AppMenu from '../AppMenu';

import PageLoad from '../PageLoad';
import { Route, Switch, Redirect } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

import { ModalRenderer } from '../../context/ModalProvider';

const useStyles = makeStyles((theme) => ({
  body: {
    display: 'flex',
    alignContent: 'stretch',
    width: '100%',
    minHeight: `calc(100vh - ${ theme.spacing(7) }px)`,
    overflow: 'hidden'
  },
  main: {
    position: 'relative',
    maxWidth: `calc(100vw - 256px)`,
  }
}));

const NotEmptyRoutes = ({ routes }) => (
  <Switch>
    <Redirect exact from="/" to="/users" />
    {
      routes.map((route) => {
        const {
          isPublic,
          component,
          ...otherProps
        } = route;
      
        if (isPublic === true) return (
          <Route
            key={ route.path }
            { ...otherProps }
            component={ component }
          />
        )
    
        return (
          <ProtectedRoute
            key={ route.path }
            { ...otherProps }
            component={ component }
          />
        )
      })
    }
  </Switch>
);

const EmptyRoutes = () => (
  <Route path='*'>
    <h1>No routes are configured</h1>
  </Route>
);


const AppShell = (props) => {
  const {
    routes = [],
    footerNavItems = [],
    authProvider: AuthProvider,
  } = props;

  if (!AuthProvider) {
    throw new Error('AppShell requires an auth provider');
  }

  const classes = useStyles();
  const menuContext = useToggle();

  return (
    <CoreProvider authProvider={ AuthProvider }>

      <div className={ classes.root }>
        <AppHeader context={ menuContext } />

        <div className={ classes.body }>
          {
            <AppMenu
              context={ menuContext }
              routes={ routes }
              footerNavItems={ footerNavItems }
            />
          }

          <Container
            component="main"
            disableGutters
            className={ classes.main }>
              <Suspense fallback={ <PageLoad /> }>
                {
                  routes?.length ?
                    <NotEmptyRoutes routes={ routes } /> :
                    <EmptyRoutes />
                }
            </Suspense>
          </Container>
        </div>
      </div>

      <ModalRenderer />
    </CoreProvider>
  )
}

export default AppShell
