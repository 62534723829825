const color = {
  purple: {
    50: '#EBE8F5',
    100: '#CDC7E5',
    200: '#ADA3D6',
    300: '#8E7DCB',
    400: '#7661C0',
    500: '#5D47B5',
    600: '#5442AF',
    700: '#473AA5',
    800: '#3B349C',
    900: '#26298E',
  },
  green: {
    50: '#EFFBEA',
    100: '#D6F4CB',
    200: '#BBECA9',
    300: '#9EE485',
    400: '#86DD68',
    500: '#72D650',
    600: '#63C547',
    700: '#4DB03D',
    800: '#389B34',
    900: '#058729',
  },
  blue: {
    50: '#E2F4FB',
    100: '#B5E2F4',
    200: '#88CFEC',
    300: '#61BCE3',
    400: '#4BAEDE',
    500: '#34A1DB',
    600: '#2F94CD',
    700: '#2881BA',
    800: '#2271A6',
    900: '#135286',
  },
  orange: {
    50: '#FDF1E2',
    100: '#FADBB8',
    200: '#F6C48C',
    300: '#F2AC62',
    400: '#EF9C48',
    500: '#ED8C35',
    600: '#E88133',
    700: '#E07330',
    800: '#D9642C',
    900: '#CE4C26',
  },
  pink: {
    50: '#F9E2F2',
    100: '#EEB6E0',
    200: '#E383CD',
    300: '#D54AB9',
    400: '#CA00AB',
    500: '#BB009C',
    600: '#AD0098',
    700: '#9B0092',
    800: '#8A008D',
    900: '#6B0082',
  },
  red: {
    50: '#FFEBEF',
    100: '#FFCED4',
    200: '#F29B9D',
    300: '#E97377',
    400: '#F45256',
    500: '#FA403C',
    600: '#EB363B',
    700: '#D92C35',
    800: '#CC252D',
    900: '#BD1621',
  },
  neutral: {
    50: '#F5F5F5',
    100: '#E9E8EB',
    200: '#DFDEE2',
    300: '#D4D3D8',
    400: '#C6C4CC',
    500: '#B2B0BA',
    600: '#7F7C8A',
    700: '#625E70',
    800: '#413C52',
    900: '#1A122F',
  },
  transparent: {
    800: 'rgba(0, 0, 0, 0.14)',
    900: 'rgba(0, 0, 0, 0.1)',
  },
  white: '#ffffff',
  grey: {
    50: '#E5E5E544',
    100: 'rgba(0, 0, 0, .12)',
    200: '#E5E5E5',
  },
  black: 'rgba(0, 0, 0, 0.87)',
}

export default color;