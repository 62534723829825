import { AxiosPromise } from 'axios';
import React, { createContext } from 'react';
import { AsyncState, GenericAsyncState } from '../hooks/useAsyncState';

import useRequest from '../hooks/useRequest';
import GenericProvider from './GenericProvider';

export type ITeam = {
	id: string
	teamName: string
	organisation: string
	numberOfUsers: number
	createdOn: string		//	"2022-01-27T23:20:14.944Z"
	modifiedOn: string	//	"2022-01-27T23:20:14.944Z"
	ancestors: string[],
}

export type IHierarchy = {
	item: ITeam,
	children?: IHierarchy[]
}

export type LookupFn = (teamId: string) => ITeam | undefined
type LookupFactoryFn = (list: ITeam[]) => LookupFn;

export type TeamList = {
	teams: ITeam[]
	hierarchy: IHierarchy[]
	lookup: LookupFn
}

const lookupFactory: LookupFactoryFn = (list: ITeam[]) => (
	(id: string) => (
		list.find(i => i.id === id)
	)
);

const teamsContext = createContext<GenericAsyncState<TeamList, Error>>({ state: AsyncState.LOADING });
export default teamsContext;

interface TeamsProviderProps { children: React.ReactElement }
export const TeamsProvider: React.FC<TeamsProviderProps> = ({ children }) => {
	const request = useRequest();

	const loadTeams = async (): Promise<TeamList> => {
		const teams: AxiosPromise<ITeam[]> = request.get(`${process.env.REACT_APP_API_URL}/Teams`);
		const hierarchy: AxiosPromise<IHierarchy[]> = request.get(`${process.env.REACT_APP_API_URL}/Teams/Hierarchy`);
		const teamsResp = await teams;
		const hierarchyResp = await hierarchy;

		return {
			teams: teamsResp.data,
			hierarchy: hierarchyResp.data,
			lookup: lookupFactory(teamsResp.data)
		}
	}

	return (
		<GenericProvider
			selfInit
			children={children}
			context={teamsContext}
			promise={loadTeams}
			reloadEvents={[
				'teams-changed',
				'user-invites-changed',
				'admin-invites-changed'
			]}
		/>
	)
};
