import React, {useMemo} from 'react'
import { BrowserRouter } from 'react-router-dom';

import Theme from '../Theme';
import { ModalProvider } from '../ModalProvider';
import { SnackbarProvider } from '../SnackbarProvider';
import { TeamsProvider } from '../TeamsProvider';
import { TeamFilterProvider } from '../TeamFilterProvider';
import { OrganisationProvider } from '../OrganisationProvider';


const providerOrder = [
  Theme,
  BrowserRouter,
  SnackbarProvider,
	ModalProvider,
  TeamsProvider,
  TeamFilterProvider,
  OrganisationProvider
];

const CoreProvider = (props) => {
  const {
    authProvider: AuthProvider,
    children
  } = props;

  const content = useMemo(() => (
    [ AuthProvider, ...providerOrder ].reverse().reduce((child, Curr) => (
      <Curr children={ child } />
    ), children)
  ), [ AuthProvider, children ]);

  return content;
}

export default CoreProvider
