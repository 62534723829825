import React from 'react'
import { makeStyles, Slide } from '@material-ui/core';
import useEmutableChildren from '../../../hooks/useEmutableChildren';
import WithView from '../WithView';

const useStyles = makeStyles({
  viewport: {
    position: 'relative',
    flex: 'auto',
  },
  view: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }
})

export const SlideView = React.memo((props) => {
  const {
    isActive,
    direction = 'up',
    children
  } = props;

  const classes = useStyles();

  return (
    <Slide className={ classes.view } direction={ direction } in={ isActive }>
      <div>
        { children }
      </div>
    </Slide>
  )
})

const SlideControl = React.memo((props) => {
  const {
    view,
    direction = 'up',
    children
  } = props;

  const classes = useStyles();

  const childList = useEmutableChildren(children, child => (
    <WithView
      key={ child.key }
      component={ SlideView }
      child={ child }
      isActive={ child.key === view }
      direction={ direction }
    />
  ));

  return (
    <div className={ classes.viewport }>
      { childList }
    </div>
  )
})

export default SlideControl
