import React from 'react';
import { Route } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';

import PageLoad from '../PageLoad';
import SplashLayout from '../Splash/SplashLayout';
import Typography from '../Typography';

export const Redirect = () => (
  <SplashLayout>
    <PageLoad />
    <Typography>Redirecting. If you are not automatically redirected in 5 seconds, refresh and try again.</Typography>
  </SplashLayout>
)

const ProtectedRoute = (props) => {
  const { component: RouteComponent, ...otherProps } = props;
  
  const {
    isAuthenticated,
    isInProgress,
    login
  } = useAuth();

  if (isInProgress)
    return (
      <Route
        { ...otherProps }
        component={ Redirect }
      />
    );
  
  
  if (!isAuthenticated) {
    login();
    return (
      <Route
        { ...otherProps }
        component={ Redirect }
      />
    );
  }

  return (
    <Route
      { ...otherProps }
      component={ RouteComponent }
    />
  );
}

export default ProtectedRoute;
