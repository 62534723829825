import React, { useEffect } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import OrgName from '../OrgName';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MenuList from '@material-ui/core/MenuList';
import { ExitToAppOutlined } from '@material-ui/icons';

import NavItem from './NavItem';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles((theme) => { 
  const drawerWidth = theme.spacing(32);

  const drawerBar = {
    minHeight: theme.spacing(7),
    width: '100%',
  }

  return (
    {
      root: {
        width: drawerWidth,
        flexShrink: 0,
        flexBasis: drawerWidth,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      drawerContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        overflow: 'auto',
      },
      drawerHeader: {
        ...drawerBar,
        alignSelf: 'flex-start',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      drawerFooter: {
        ...drawerBar,
        alignSelf: 'flex-end',
        borderTop: `1px solid ${ theme.palette?.hr?.onWhite }`,
      },
      drawerBody: {
        flexBasis: theme.spacing(12),
        flexGrow: 14,
        flexShrink: 0,
      },
      mainNav: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      listItemIcon: {
        minWidth: theme.spacing(4.5),
      },
    }
  )
});

const AppMenu = (props) => {
  const {
    context = {},
    routes = [],
    footerNavItems = []
  } = props;

  const classes = useStyles();
  const isPermanentDrawer = useMediaQuery(theme => theme.breakpoints.up('md'))
  const { isAuthenticated, logout } = useAuth();
  const history = useHistory();

  useEffect(
    () => {
      if (isPermanentDrawer) return;
      return history.listen(context.close);
    },
    [context.close, history, isPermanentDrawer]
  )

  const onLogout = () => {
    logout();
    if (context.close) context.close();
  }

  if (!isAuthenticated) return <></>;

  return (
    <Drawer
      anchor='left'
      variant={ isPermanentDrawer ? 'permanent' : 'temporary'}
      open={ isPermanentDrawer ? true : context.isShowing }
      onClose={ () => context.close() }
      className={ classes.root }
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={ classes.drawerContainer }>
        <div className={ classes.drawerHeader }></div>
        <OrgName />
        <div className={ classes.drawerBody}>
          <MenuList className={ classes.mainNav }>
            {
              routes
                .filter(route => !!route.label)
                .map(route => {
                  const { label } = route;

                  return (
                    <NavItem
                      divider={(label === 'Admins')}
                      key={ label }
                      { ...route }
                    />
                  )
                })
            }
          </MenuList>
        </div>

        <div className={ classes.drawerFooter }>
          {
            isAuthenticated && (
              <MenuList>
                {
                  footerNavItems.map(FooterNavItem => (
                    <FooterNavItem
                      closeNav={ () => isPermanentDrawer ? false : context.close }
                    />
                  ))
                }
                <NavItem
                  label='Sign Out'
                  onClick={ onLogout }
                  icon={ <ExitToAppOutlined/> }
                />
              </MenuList>
            )
          }
        </div>
      </div>
    </Drawer>
  )
}

export default AppMenu
