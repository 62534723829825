import { useEffect, useState } from 'react';

const defaultFn = () => {}

//  this is useful when you want to trigger a call after the dom has been updated,
//  primarly used to call a function that requires a ref with NodeElements.
const useEffectCallback = (callback = defaultFn) => {
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ trigger, setTrigger ] = useState();
  
  useEffect(() => {
    if (isLoaded) return callback();
    else setIsLoaded(true);
  }, [ trigger ]);

  return () => setTrigger(Math.random());
}

export default useEffectCallback;