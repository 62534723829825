import React, { useEffect, useRef } from 'react';
import {
  Grow,
  Paper,
  Popper,
  MenuList,
  IconButton,
  ClickAwayListener,
  makeStyles,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import useToggle from '../../hooks/useToggle';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  }
})

export default function ContextMenu({ children }) {
  const anchorRef = useRef();
  const { isShowing, toggle, close } = useToggle(false);
  const classes = useStyles();

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    close();
  }

  const onKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      close();
    }
  }

  const prevOpen = useRef(isShowing);
  useEffect(() => {
    if (prevOpen.current === true && isShowing === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = isShowing;
  }, [isShowing]);

  if (!children) return <></>;
  return (
    <div className={ classes.root }>
      <IconButton ref={ anchorRef } onClick={ () => toggle() }>
        <MoreVert />
      </IconButton>

      <Popper placement='bottom-end' open={ isShowing } anchorEl={ anchorRef.current } transition disablePortal>
        {
          ({ TransitionProps }) => (
            <Grow { ...TransitionProps }>
              <Paper elevation={8} className={ classes.menu }>
                <ClickAwayListener onClickAway={ handleClose }>
                  <MenuList autoFocusItem={ isShowing } onKeyDown={ onKeyDown } onClick={ handleClose }>
                    { children }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )
        }
      </Popper>
    </div>
  )
}
