import React from 'react'
import { makeStyles } from '@material-ui/core';
import useClassList from '../../hooks/useClassList';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.manrope,
    fontWeight: 500,
    margin: 0,
  },

  link: {
    color: theme.palette.text.link,
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  lowEmphasis: {
    color: theme.palette.text.lowEmphasis,
  },
  midEmphasis: {
    color: theme.palette.text.mediumEmphasis
  },

  titlePreset4: {
    fontFamily: theme.typography.poppins,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(40),
    lineHeight: theme.typography.pxToRem(48),
    letterSpacing: -.8,
  },

  headingPreset1: {
    fontFamily: theme.typography.poppins,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(26),
    lineHeight: theme.typography.pxToRem(32),
    letterSpacing: -.3,
    margin: ({ disableMargin }) => (
      !disableMargin ?
        theme.spacing(5, 0, 2):
        0
    ),
  },
  headingPreset2: {
    fontFamily: theme.typography.poppins,
    fontSize: theme.typography.pxToRem(21),
    lineHeight: theme.typography.pxToRem(28),
    letterSpacing: -.15,
    margin: ({ disableMargin }) => (
      !disableMargin ?
        theme.spacing(5, 0, 2.5):
        0
    ),
  },
  headingPreset3: {
    fontFamily: theme.typography.poppins,
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(24),
    margin: ({ disableMargin }) => (
      !disableMargin ?
        theme.spacing(5, 0, 2.5):
        0
    ),
  },
  headingPreset4: {
    fontFamily: theme.typography.poppins,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(20),
    letterSpacing: -.1,
    margin: ({ disableMargin }) => (
      !disableMargin ?
        theme.spacing(5, 0, 2.5):
        0
    ),
  },

  paragraphPreset1: {
    fontSize: theme.typography.pxToRem(21),
    lineHeight: theme.typography.pxToRem(32),
    letterSpacing: -.1,
    marginBottom: ({ disableMargin }) => (
      !disableMargin ?
        theme.spacing(1.25) :
        0
    ),
  },
  paragraphPreset2: {
    fontSize: theme.typography.pxToRem(17),
    lineHeight: theme.typography.pxToRem(28),
    letterSpacing: -.1,
    marginBottom: ({ disableMargin }) => (
      !disableMargin ?
        theme.spacing(1.25) :
        0
    ),
  },
  paragraphPreset3: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: .1,
    marginBottom: ({ disableMargin }) => (
      !disableMargin ?
        theme.spacing(1.25) :
        0
    ),
  },
  paragraphPreset4: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    letterSpacing: .2,
    marginBottom: ({ disableMargin }) => (
      !disableMargin ?
        theme.spacing(1.25) :
        0
    ),
  },
  
  labelPreset1: {
    fontFamily: theme.typography.poppins,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: .2,
  },
  labelPreset2: {
    fontFamily: theme.typography.poppins,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: .2,
  },
  labelPreset3: {
    fontFamily: theme.typography.poppins,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(8),
    letterSpacing: .3,
  },
}));

const componentMap = {
  titlePreset4: 'h1',
  headingPreset1: 'h2',
  headingPreset2: 'h3',
  headingPreset3: 'h4',
  headingPreset4: 'h5',
  paragraphPreset1: 'p',
  paragraphPreset2: 'p',
  paragraphPreset3: 'p',
  paragraphPreset4: 'p',
  labelPreset1: 'label',
  labelPreset2: 'label',
  labelPreset3: 'label',
}

const Typography = React.forwardRef((props, ref) => {
  const {
    type = 'paragraph',
    preset = 3,
    disabled = false,
    emphasis = 'normal',
    link = false,
    component,

    disableMargin = true,

    classes,
    className,
    
    children,
    ...otherProps
  } = props;

  const internalPreset = `${ type }Preset${ preset }`;
  const HTMLComponent = component ?? componentMap[internalPreset] ?? 'p';

  const classStyles = useStyles({ emphasis, disableMargin });

  const classList = useClassList(
    classStyles.root,
    classStyles[internalPreset],

    disabled === true ? classStyles.disabled : {},
    emphasis === 'mid' ? classStyles.midEmphasis : {},
    emphasis === 'low' ? classStyles.lowEmphasis : {},
    link === true ? classStyles.link : {},

    //  extend styles
    ...(classes ? Object.values(classes) : []),
    className ?? ''
  );

  return (
    <HTMLComponent ref={ ref } className={ classList } { ...otherProps }>
      { children }
    </HTMLComponent>
  )
})

export default Typography
