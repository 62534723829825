import React, { createRef } from 'react';
import { SnackbarProvider as NotistackProvider } from 'notistack';

import {
  makeStyles,
  IconButton
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.poppins,
    padding: theme.spacing(1, 3),
    flexWrap: 'nowrap',
    maxWidth: '480px',
  },
  message: {
    textAlign: 'center',
    margin: '0 auto',
  },
  closeButton: {
    color: 'white',
  },
}));

const SnackbarProvider = (props) => {
  const { children } = props;
  const notistackRef = createRef();

  const classes = useStyles();

  const handleDismissSnackbar = key => () => {
    notistackRef.current.closeSnackbar(key);
  }

  return (
    <NotistackProvider
      ref={notistackRef}
      classes={{
        contentRoot: classes.root,
        message: classes.message,
      }}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      hideIconVariant
      dense
      disableWindowBlurListener
      autoHideDuration={2000}
      action={(key) => (
        <IconButton
          className={classes.closeButton}
          onClick={ handleDismissSnackbar(key) }
          aria-label='dismiss'
        >
          <CloseIcon />
        </IconButton>
      )}
    >
      {children}
    </NotistackProvider>
  );
}

export default SnackbarProvider;