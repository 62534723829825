import axios from 'axios'

const interceptors = new Map();

const useRequest = () => {
  const reduceInterceptor = config => (
    Array.from(interceptors.entries())
      .filter(([match]) => match(config) === true)
      .map(([,mapFn]) => mapFn)
  )

  const mapRequest = config => (
    reduceInterceptor(config)
      .reduce(
        (prev, mapFn) => ({
          ...prev,
          ...mapFn(prev),
        }),
        config
      )
  )

  const setInterceptor = (matchFn, mapFn) => {
    interceptors.set(matchFn, mapFn);
  }

  const get = (url, headers = {}) => {
    const newConfig = mapRequest({ url, headers });

    return axios({
      ...newConfig,
      headers: {
        ...headers,
        ...(newConfig.headers ?? {})
      },
      method: 'GET'
    })
  }

  const post = (url, data, headers = {}) => {
    const newConfig = mapRequest({ url, data, headers });

    return axios({
      ...newConfig,
      headers: {
        ...headers,
        ...(newConfig.headers ?? {})
      },
      method: 'POST'
    })
  }

  return {
    setInterceptor,

    get,
    post,
  }
}

export default useRequest;