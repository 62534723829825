import React from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  navItem: {
    overflow: 'hidden',
    borderRadius: '0px 20px 20px 0px',
    padding: 0,
    marginRight: '16px',
    minHeight: '0', // The mui MenuItem gets a minimum height on mobile which this undoes for all viewports
    '&:not(:last-child)': {
      marginBottom: theme.spacing(.5),
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary[50],
    },
  },
  hitArea: {
    padding: theme.spacing(1, 2.5),
    width: '100%',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  icon: {
    minWidth: theme.spacing(4.5),
    '& svg': {
      fill: theme.palette.neutral[700],
      '.Mui-selected &': {
        fill: theme.palette.primary[600],
      }
    },
  },
  label: {
    color: theme.palette.neutral[700],
    '.Mui-selected &': {
      color: theme.palette.primary[600]
    }
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));


const NavItem = (props) => {
  const {
    icon,
    label,

    path = '',
    divider = false,
    onClick
  } = props;

  const classes = useStyles();
  const { pathname } = useLocation();
  const parentPathname = pathname.replace(/^\/([^/]*).*$/, '/$1');
  const isActive = parentPathname === path;


  const navItem = (
    <>
      <ListItemIcon className={ classes.icon }>
        { icon }
      </ListItemIcon>
      <Typography type='label' preset={1} component='span' className={ classes.label }>
        { label }
      </Typography>
    </>
  )

  if (typeof onClick === 'function') return (
    <MenuItem
      selected={ isActive }
      className={ clsx(classes.navItem, classes.hitArea) }
      onClick={ () => onClick() }
    >
      { navItem }
    </MenuItem>
  );

  return (
    <>
      {(divider) && <Divider variant='middle' className={classes.divider}/>}
      <MenuItem
        selected={ isActive }
        className={ classes.navItem }
      >
        <Link
          to={ path }
          className={ clsx(classes.link, classes.hitArea) }
        >
          { navItem }
        </Link>
      </MenuItem>
    </>
  )
}

export default NavItem;
