import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";

const policies = {
  signIn: process.env.REACT_APP_SIGNIN_POLICY,
  resetPassword: process.env.REACT_APP_RESETPASSWORD_POLICY,
}

const IS_SIGNUP = (window.location.hash.indexOf('id_token') > -1)

const tenant = process.env.REACT_APP_TENANT ?? '';
const applicationID = process.env.REACT_APP_APPLICATION_ID;
const accessScope = process.env.REACT_APP_ACCESS_SCOPE;

const authPolicy = policies.signIn;
const reactRedirectUri = process.env.REACT_APP_URL;
const tenantSubdomain = (tenant).split(".")[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/tfp/`;
const signInAuthority = `${instance}${tenant}/${authPolicy}`;

// Only the Sign In policy exists for this app.
// If the Sign Up flow redirected here, refresh the page.
// Otherwise this will error out.

if (IS_SIGNUP) {
  window.location.replace(window.location.origin);
}

const log = (() => {
  /* eslint-disable */
  // TODO: disable logging on production env
  let logItems = [];
  let timer;

  const msalPrint = () => {
    console.groupCollapsed('[MSAL]');
    logItems.forEach(log => console.log(...log));
    console.groupEnd();
  }

  return (...args) => {
    logItems = [ ...logItems, args ];

    if (timer) clearTimeout(timer);
    timer = setTimeout(msalPrint, 1000);
  };

  /* eslint-enable */
})();

const config = {
  auth: {
    authority: signInAuthority,
    clientId: applicationID,
    redirectUri: reactRedirectUri,
    validateAuthority: false,

    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: false
  },
  // Enable logging of MSAL events for easier troubleshooting.
  // This should be disabled in production builds.
  system: {
    logger: new Logger(
      (logLevel, message) => {
        log("[MSAL]", message);
      },
      {
        level: LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    )
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
  }
};

const params = {
  scopes: [  
    accessScope
  ],
}

const options = {
  loginType: LoginType.Redirect,
  // When a token is refreshed it will be done by loading a page in an iframe.
  // Rather than reloading the same page, we can point to an empty html file which will prevent
  // site resources from being loaded twice.
  tokenRefreshUri: `${ window.location.origin }/auth.html`
}

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.

const authProvider = new MsalAuthProvider(config, params, options);

const authRedirectCallback = (error) => {
  // If user is trying to reset password
  if (error) {
    if (error.errorMessage.indexOf("AADB2C90118") > -1) {
      authProvider.loginRedirect({
        authority: `${instance}${tenant}/${policies.resetPassword}`
      })
    }
  }
}

authProvider.handleRedirectCallback(authRedirectCallback);

export default authProvider;
