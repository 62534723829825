import { makeStyles } from '@material-ui/core';
import React from 'react'
import { ReactComponent as LogoAsset } from './innowell-logo.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    margin: theme.spacing(0, 1),
    marginRight: theme.spacing(3) + 1,
    height: 30
  }
}));

const InnoWellLogo = () => {
  const classes = useStyles();
  return (
    <LogoAsset
      className={ classes.root }
    />
  )
}

export default InnoWellLogo
