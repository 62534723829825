import React from 'react';
import { GroupOutlined, AssessmentOutlined } from '@material-ui/icons';

const Users = React.lazy(() => import('./pages/Users/Users'));
const Admins = React.lazy(() => import('./pages/Admins/Admins'));
const Teams = React.lazy(() => import('./pages/Teams/Teams'));
const Reports = React.lazy(() => import('./pages/Reports/Reports'))

const routes = [
  {
    path: '/users',
    label: 'Users',
    icon: <GroupOutlined />,
    component: Users,
  },
  {
    path: '/reports',
    label: 'Reporting',
    icon: <AssessmentOutlined />,
    component: Reports,
  },
  {
    path: '/admins',
    label: 'Admins',
    icon: <GroupOutlined />,
    component: Admins,
  },
  {
    path: '/teams',
    label: 'Teams',
    icon: <GroupOutlined />,
    component: Teams,
  },
]

export default routes;