import React from 'react'
import { makeStyles } from '@material-ui/core'
import useEmutableChildren from '../../../hooks/useEmutableChildren'
import WithView from '../WithView'

const useStyles = makeStyles(theme => ({
	container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  animator: {
    display: 'flex',
    transition: '.3s ease-in-out transform',
    transform: ({ position = 0, max = 2 }) => `translateX(-${(100 / (max + 1)) * position}%)`,
    width: ({ max = 2 }) => `${(max + 1) * 100}%`,
    height: '100%',
  },
	slider: {
    overflowY: 'auto',
    width: ({ max = 2 }) => `${ 100/ (max + 1) }%`,
    paddingBottom: theme.spacing(7.5),
  }
}))

export const CarouselView = React.memo((props) => {
	const {
		children,
		className
	} = props

	return (
		<div className={className}>
			{children}
		</div>
	)
})

const Carousel = React.forwardRef((props, ref) => {
	const {
		children,
		position,
	} = props

	const classes = useStyles({ position, max: children.flat(Infinity).length });
	
	const childList = useEmutableChildren(children, child => (
		<WithView
			key={ child.key }
			component={ CarouselView }
			child={child}
			className={ classes.slider }
		/>
	))

	return (
		<div className={ classes.container }>
			<div className={ classes.animator }>
				{childList}
			</div>
		</div>
	)
})

export default Carousel
