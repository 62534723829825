import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    maxWidth: (props) => props.fullWidth ? 'none' : theme.spacing(40),
    margin: theme.spacing(2.5, 'auto'),
    width: '100%',
    padding: theme.spacing(0, 2),
  },
  media: {
    backgroundColor: 'transparent',
    width: theme.spacing(10),
    height: theme.spacing(10),
    margin: theme.spacing(0, 'auto', 2.5),
    overflow: 'visible',
    '& svg': {
      fill: theme.palette.grey.main,
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    '& img': {
      width: '100%',
      height: 'auto',
    }
  },
  title: {
    marginBottom: theme.spacing(1.5),
  },
  action: {
    marginTop: theme.spacing(2.5),
  }
}));

const SplashLayout = (props) => {
  const {
    image,
    title,
    children,
    action,
    disablePosition = false,
    fullWidth = false,
  } = props;
  
  const classes = useStyles({ disablePosition, fullWidth });

  return (
    <>
      <section className={ classes.root }>
        {
          (image) && (
            <Avatar className={ classes.media }>
              { image }
            </Avatar>
          )
        }
        <Typography type='heading' preset={1} className={ classes.title }>
            { title }
        </Typography>
        <div>
          { children }
        </div>
        {
          (action) &&
          <div className={classes.action}>
            { action }
          </div>
        }
      </section>
    </>
  )
}

export default SplashLayout
