// default font families
const fontFamily = {
  manrope: '"Manrope", "Helvetica", "Arial", sans-serif',
  poppins: '"Poppins", "Helvetica", "Arial", sans-serif',
}

const typography = {
  fontFamily,
}

export { fontFamily };
export default typography;